/**
 *  Sagas for the cmspage resource managment using Swagger client
 * 
 * Each saga watcher intercepts a trigger action, does the asyncrhonous work in the respective worker saga and dispatches a success or a failure action.
 * Fetch calls are made via the swagger tags interface requests
 * 
 * TODO: update and create are not working through swagger client cause we dont know how to upload a picture along an id
 */

import { call, put, takeEvery, select } from 'redux-saga/effects'
import buildHeaders from '../../utils/buildHeaders';
import actions from './actions';
import {isServer} from "../store";
import apiClient, {applyHeaders} from '../../utils/apiSwaggerRequest';
import apiRequest from '../../utils/apiRequest';
import { push } from 'connected-react-router';

/** Worker Sagas */

/** List Saga
 *  @description: connects to the getCmsPages operation
 */
export function* list() {
  /** if we are offline we use persisted data */
  if ( !isServer && navigator && !navigator.onLine) { 
    let storedList = []
    const storedCmsPages = yield select(state => state.cmspages.stored);
    Object.keys(storedCmsPages).forEach(storedCmsPageIndex => {
      storedList.push(Object.assign({}, storedCmsPages[storedCmsPageIndex]))
    })
    yield put({ type: actions.listSuccess, payload: storedList });
  } else { 
    /**  else we are online -> we fetch */
    let headers = buildHeaders();
    try {
      const SwaggerClient = yield call(apiClient)
      const payload = yield call(SwaggerClient.apis.CmsPages.getCmsPages, {}, {requestInterceptor: applyHeaders(headers)})
      yield put({type: actions.listSuccess, payload: payload.obj});
    } catch (e) {
      yield put({type: actions.listFail, payload: e});
    }
  }
}

/** Show Saga
 *  @description: connects to the showCmsPage operation
 *  @param {number} action.payload the cmspage id
 */
export function* show(action) {
  const cmspageSlug = action.payload;
  const storedCmsPages = yield select(state => state.cmspages.stored);
  /** if we are offline we use persisted data */
  if (!isServer && (navigator && !navigator.onLine) && storedCmsPages[cmspageSlug]) {
    yield put({
      type: actions.showSuccess,
      payload: storedCmsPages[cmspageSlug]
    });
  } else { // else we are online -> we fetch
    let headers = buildHeaders();
    try {
      const SwaggerClient = yield call(apiClient)
      const payload = yield call(SwaggerClient.apis.CmsPages.showCmsPage, {slug: cmspageSlug}, {requestInterceptor: applyHeaders(headers)})
      yield put({type: actions.showSuccess, payload: payload.obj});
    } catch (e) {
      yield put({type: actions.showFail, payload: e});
    }
  }
}

/** Create Saga  --> needs fix, we dont use swagger client for this endpoint
 *  @description: connects to the createCmsPage operation. If cmspage contains a picture also connects to the uploadCmsPage operation
 *  @param {Object} action.payload the cmspage to create
 */
export function* create(action) {
  let headers = buildHeaders();
  let values = action.payload;
  let body = JSON.stringify(values)
  if (values.picture) {
    body = new FormData();
    Object.keys(values).forEach(key => {
      if (key !== 'picture') {  // skip picture since its a file
        body.append(`cmspage[${key}]`, values[key]);
      }
    })
    body.append("cmspage[picture]", values.picture[0], values.picture[0].name); // add the file
    delete headers["Content-Type"]  // remove header
  }
  try {
    const payload = yield call(apiRequest, `/cmspages`, { body, method: 'POST', headers });
    yield put({type: actions.createSuccess, payload: payload});
    yield put(push('/cmspages/' + payload.id));
  } catch (e) {
    yield put({type: actions.createFail, payload: e});
  }
}

/** Update Saga --> needs fix, we dont use swagger client for this endpoint
 *  @description: connects to the updateCmsPage operation. If cmspage contains a picture also connects to the uploadCmsPage operation
 *  @param {Object} action.payload the cmspage to update
 */
export function* update(action) {
  let headers = buildHeaders();
  let values = action.payload;
  delete values.user_id
  let body = JSON.stringify(values)
  if (values.picture) {
    body = new FormData();
    Object.keys(values).forEach(key => {
      if (key !== 'picture') {  // skip picture since its a file
        body.append(`cmspage[${key}]`, values[key]);
      }
    })
    body.append("cmspage[picture]", values.picture[0], values.picture[0].name); // add the file
    delete headers["Content-Type"]  // remove header
  }
  try {
    const payload = yield call(apiRequest, `/cmspages/${action.payload.id}`, { body, method: 'PUT', headers });
    yield put({type: actions.updateSuccess, payload: payload});
    yield put(push('/cmspages/' + payload.id));
  } catch (e) {
    yield put({type: actions.updateFail, payload: e});
  }
 }

/** Remove Saga
 *  @description: connects to the deleteCmsPage operation.
 *  @param {Object} action.payload the id of the cmspage to delete
 */
export function* remove(action) {
  let headers = buildHeaders();
  try {
    const SwaggerClient = yield call(apiClient)
    let payload = yield call(SwaggerClient.apis.CmsPages.deleteCmsPage, {id: action.payload.id}, {requestInterceptor: applyHeaders(headers)});
    yield put({type: actions.removeSuccess, payload: payload});
  } catch (e) {
    yield put({type: actions.removeFail, payload: e});
  }
}

/** Unstore Saga
 *  @description: handles the cache cleaning when an cmspage is unstored
 */
export function* unstoreCmsPage() {
  const image = yield select(state => state.cmspages.show.image);
  caches.open('images-cache')
  .then(imagesCache => {
    console.log(imagesCache)
    return imagesCache.delete(image)
  })
  .then(response => {
    console.log(response)
  })
  .catch(err => {
    console.log('Could not clear cache', err);
  })
}

/**
 * Saga Watchers
 * The exported list of sagas registered. When one of the action types is dispatched 
 * the related worker saga is invoked.
 * Each saga is executed in a different thread
 */
function* cmspagesSaga() {
  yield takeEvery(actions.list, list);
  yield takeEvery(actions.show, show);
  yield takeEvery(actions.create, create);
  yield takeEvery(actions.update, update);
  yield takeEvery(actions.remove, remove);
  yield takeEvery(actions.unstoreCmsPage, unstoreCmsPage);
}
export default cmspagesSaga;
