/**
 * The root saga function. This function is used to register all sagas watching the store
 */
import itemsSaga from './items/sagas'
import notificationsSaga from './notifications/sagas'
import { all } from 'redux-saga/effects'
import xapisSaga from './xapis/sagas';
import accountSaga from './account/sagas'
import serviceWorkerSaga from './servWork/sagas'
// DON'T DELETE THE FOLLOWING COMMENT: NEEDED FOR UPDATING THIS FILE WITH THE SCAFFOLD SCRIPT
// new scaffolded imports
import cmspagesSaga from './cmspages/sagas_swagger';
import trainingsSaga from './trainings/sagas';
import shopSaga from './shop/sagas_swagger';

/** exports the rootSaga for the store */
export default function* rootSaga() {
  yield all([
    itemsSaga(),
    notificationsSaga(),
    accountSaga(),
    serviceWorkerSaga(),
    shopSaga(),
    // DON'T DELETE THE FOLLOWING COMMENT: NEEDED FOR UPDATING THIS FILE WITH THE SCAFFOLD SCRIPT
    // new scaffolded sagas
	  cmspagesSaga(),
    xapisSaga(),
    trainingsSaga(),
  ])
}
