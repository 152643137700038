/** 
 * The app index 
 */
import React from "react";
import { render, hydrate } from 'react-dom';
import { Provider } from "react-redux"
import { ConnectedRouter } from 'connected-react-router'
import configureStore from "./redux/store";
import configureWorker from "./utils/serviceWorkerConfig";
import {isServer} from "./redux/store";
import "./index.sass";
import Loadable from 'react-loadable';
import 'font-awesome/css/font-awesome.min.css';
import { Frontload } from 'react-frontload';
import Root from "./routes/Root";
import * as serviceWorker from "./utils/serviceWorker";
import I18n from "redux-i18n"
import {translations} from "./locales/translations"
import rootSaga from '../src/redux/rootSaga'

/** create a store */
const {store, history} = configureStore();

/** run sagas */
store.runSaga(rootSaga)

/** build the service worker configuration object */
var config = configureWorker(store)
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
if (!isServer) {
  serviceWorker.register(config);
} else {
  serviceWorker.unregister();
}

/** The application */
const Application = (
  <Provider store={store}>
    <I18n translations={translations} initialLang="de">
      <ConnectedRouter history={history}>
        <Frontload noServerRender={true}>
          <Root />
        </Frontload>
      </ConnectedRouter>
    </I18n>
  </Provider>
);

/** the root element to load react */
const root = document.querySelector('#root');

/**
 * the index main
 */
if (root.hasChildNodes() === true) {
  // If it's an SSR, we use hydrate to get fast page loads by just
  // attaching event listeners after the initial render
  Loadable.preloadReady().then(() => {
    hydrate(Application, root);
  });
} else {
  // If we're not running on the server, just render like normal
  render(Application, root);
}