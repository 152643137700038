export const translations = {
  'de': {
    'Name': 'Name',
    'Description': 'Beschreibung',
    'Submit': 'Abschicken',
    'Items.header': 'Items',
    'Items.header.offline': 'Items',
    'YYYY-MM-DD': 'DD.MM.YYYY',
    'LoadingContent.message': 'Wird geladen',
    'Email': 'Email',
    'Password': 'Passwort',
    'ForgotPassword': 'Passwort vergessen',
    'Register': 'Registrieren',
    'Login': 'Einloggen',
    'Back': 'Zurück',
    'Barrierefreifahrt': 'Barreierefreifahrt',
    'Profile': 'Profil',
    'Trainings': 'Einweisungen',
    'Shop': 'Shop',
    'About': 'Über',
    'Imprint': 'Impressum',
    'Dataprivacy': 'Datenschutz',
    'Logout': 'Ausloggen',
    'NewPassword': 'Neues Passwort',
    'NewPasswordConfirmation': 'Neues Passwort bestätigen',
    'First Name': 'Vorname',
    'Last Name': 'Nachname',
    'Delete Account': 'Konto löschen',
    'PasswordConfirmation': 'Passwort bestätigen',
    'Number': 'Zahl',
    'Text': 'Text',
    'TextArea': 'Textfeld',
    'checkbox': 'Checkbox',
    'Switch': 'Switch',
    'Male': 'Männlich',
    'Female': 'Weiblich',
    'RadioGroup': 'RadioGroup',
    'select': 'Select',
    'multi-select': 'Multi-Select',
    'Training.locked': 'Einweisung ausstehend',
    'Training.completed': 'Einweisung abgeschlossen',
    'Training.repeat': 'Einweisung wiederholen',
    'Training.start': 'Einweisung starten',
    'Trainings.header': 'Einweisungen',
    'Congratulations!': 'Glückwunsch',
    'The training for': 'Die Einweisung für',
    'is completed successfully.': 'Wurde erfolgreich abgeschlossen',
    'Weiter': 'Weiter',
    'Are you sure you want to delete your account?': 'Sind Sie sicher, dass Ihr Account gelöscht werden soll?',
    'Sie sind für dieses Gerät noch nicht Authorisiert': 'Sie sind für dieses Gerät noch nicht Authorisiert',
    'Führen Sie bitte erst die notwendige Einweisung durch': 'Führen Sie bitte erst die notwendige Einweisung durch',
    'Es konnte keine Verbindung hergestellt werden': 'Es konnte keine Verbindung hergestellt werden',
    'Bitte versuchen sie es nochmal': 'Bitte versuchen sie es nochmal',
    'Einweisung durchführen': 'Einweisung durchführen',
    'Zurück': 'Zurück',
    'ForOFour.message': 'Seite nicht gefunden',
    'ForOFour.button': 'Zur Startseite',
    'Home': 'Start',
    'Searching': 'Sucht',
    'Search Devices': 'Geräte suchen',
    'unknown device': 'Unbekanntes Gerät',
    'unknown': 'unbekannt',
    'unknown devices': 'Unbekannte Geräte',
    'Next': 'Weiter',
    'Complete': 'Abschließen',
  },
  'options': {
    'plural_rule': 'n != 1',
    'plural_number': '2',
  },
  'en': {
    'Name': '',
    'Description': '',
    'Submit': 'Submit',
    'Items.header': 'Welcome To Bakerplate',
    'Items.header.offline': 'Welcome To Offline Bakerplate',
    'YYYY-MM-DD': 'YYYY-MM-DD',
    'LoadingContent.message': '',
    'Email': 'Email',
    'Password': 'Password',
    'ForgotPassword': 'Forgot your password? Recover',
    'ForgotPassword.here': 'here',
    'Profile': 'Profile',
    'NewPassword': 'New Password',
    'NewPasswordConfirmation': 'New Password Confirm',
    'First Name': 'First Name',
    'Last Name': 'Last Name',
    'Delete Account': 'Delete Account',
    'PasswordConfirmation': 'Password Confirm',
    'Number': 'Number',
    'Text': 'Text',
    'TextArea': '',
    'checkbox': '',
    'Switch': '',
    'Male': '',
    'Female': '',
    'RadioGroup': '',
    'select': '',
    'multi-select': '',
    'Are you sure you want to delete your account?': 'Are you sure you want to delete your account?',
    'ForOFour.message': '',
    'ForOFour.button': '',
    'Home.header': 'Welcome To Bakerplate',
    'Home.header.offline': 'Welcome To Offline Bakerplate',
  },
  'it': {
    'Name': '',
    'Description': '',
    'Submit': 'Invia',
    'Items.header': 'Ciao Bakerplate',
    'Items.header.offline': 'Ciao offline Bakerplate',
    'YYYY-MM-DD': 'DD.MM.YYYY',
    'LoadingContent.message': '',
    'Email': 'Email',
    'Password': 'Password',
    'ForgotPassword': 'Dimenticato le credenziali? Clicca',
    'ForgotPassword.here': 'qui',
    'Profile': 'Profilo',
    'NewPassword': 'Nuova Password',
    'NewPasswordConfirmation': 'Conferma Nuova Password',
    'First Name': 'Nome',
    'Last Name': 'Cognome',
    'Delete Account': 'Cancellare l’account',
    'PasswordConfirmation': 'Conferma Password',
    'Number': '',
    'Text': '',
    'TextArea': '',
    'checkbox': '',
    'Switch': '',
    'Male': '',
    'Female': '',
    'RadioGroup': '',
    'select': '',
    'multi-select': '',
    'ForOFour.message': '',
    'ForOFour.button': '',
    'Home.header': 'Ciao Bakerplate',
    'Home.header.offline': 'Ciao offline Bakerplate',
  },
}
