/**
 * Sagas for the item resource managment
 * 
 * Each saga watcher intercepts a trigger action, does the asyncrhonous work in the respective worker saga and dispatches a success or a failure action.
 */
import { call, put, takeEvery, select } from 'redux-saga/effects'
import apiRequest from '../../utils/apiRequest';
import buildHeaders from '../../utils/buildHeaders';
import actions from './actions';
import { push } from 'connected-react-router';
import {isServer} from "../store";

/** Worker Sagas */

/** List Saga
 *  @description: connects to the `index` endpoint to fetch a list of items
 */
export function* list(action) {
  /** if we are offline we use persisted data */
  if ( !isServer && navigator && !navigator.onLine) { 
    let storedList = []
    const storedItems = yield select(state => state.items.stored);
    Object.keys(storedItems).forEach(storedItemIndex => {
      storedList.push(Object.assign({}, storedItems[storedItemIndex]))
    })
    yield put({ type: actions.listSuccess, payload: storedList });
  } else {
    /**  else we are online -> we fetch */
    const currentUser = yield select(state => state.account.currentUser);
    let headers = buildHeaders(currentUser)
    try {
      const payload = yield call(apiRequest, `/items.json`, {headers: headers});
      yield put({type: actions.listSuccess, payload: payload});
    } catch (e) {
      yield put({type: actions.listFail, payload: e});
    }
  }
}
/** Show Saga
 *  @description: connects to the `show` endpoint to fetch an item by id
 *  @param {number} action.payload the item id
 */
export function* show(action) {
  const itemId = action.payload;
  const storedItems = yield select(state => state.items.stored);
  /** if we are offline we use persisted data */
  if (!isServer && (navigator && !navigator.onLine) && storedItems[itemId]) {
    yield put({
      type: actions.showSuccess,
      payload: storedItems[itemId]
    });
  } else {
    /**  else we are online -> we fetch */
    const currentUser = yield select(state => state.account.currentUser);
    let headers = buildHeaders(currentUser)
    try {
      const payload = yield call(apiRequest, `/items/${itemId}.json`, {headers: headers});
      yield put({type: actions.showSuccess, payload: payload});
    } catch (e) {
      yield put({type: actions.showFail, payload: e});
    }
  }
}
/** Create Saga
 *  @description: connects to the `create` endpoint to create an item
 *  If the resource contains a file (values.picture) it will post a formdata object rather than a json.
 *  - redirects to the created item show page
 * @param {Object} action.payload the item object
 */
export function* create(action) {
    const currentUser = yield select(state => state.account.currentUser);
    let headers = buildHeaders(currentUser)
  let values = action.payload;
  let body = JSON.stringify(values)
  if (values.picture) {
    body = new FormData();
    Object.keys(values).forEach(key => {
      if (key !== 'picture') {  // skip picture since its a file
        body.append(`item[${key}]`, values[key]);
      }
    })
    body.append("item[picture]", values.picture[0], values.picture[0].name); // add the file
    delete headers["Content-Type"]  // remove header
  }
  try {
    const payload = yield call(apiRequest, `/items`, { body, method: 'POST', headers });
    yield put({type: actions.createSuccess, payload: payload});
    yield put(push('/items/' + payload.id));
  } catch (e) {
    yield put({type: actions.createFail, payload: e});
  }
}

/** Update Saga
 *  @description: connects to the `update` endpoint to update an item by id
 *  If the resource contains a file (values.picture) it will post a formdata object rather than a json.
 *  - redirects to the created item show page
 * @param {Object} action.payload the item object
 */
export function* update(action) {
    const currentUser = yield select(state => state.account.currentUser);
    let headers = buildHeaders(currentUser)
  let values = action.payload;
  delete values.user_id
  let body = JSON.stringify(values)
  if (values.picture) {
    body = new FormData();
    Object.keys(values).forEach(key => {
      if (key !== 'picture') {  // skip picture since its a file
        body.append(`item[${key}]`, values[key]);
      }
    })
    body.append("item[picture]", values.picture[0], values.picture[0].name); // add the file
    delete headers["Content-Type"]  // remove header
  }
  try {
    const payload = yield call(apiRequest, `/items/${action.payload.id}`, { body, method: 'PUT', headers });
    yield put({type: actions.updateSuccess, payload: payload});
    yield put(push('/items/' + payload.id));
  } catch (e) {
    yield put({type: actions.updateFail, payload: e});
  }
 }

/** Remove Saga
 *  @description: connects to the `delete` endpoint to remove an item by id
 *  @param {Object} action.payload the id of the item to delete
 */
 export function* remove(action) {
    const currentUser = yield select(state => state.account.currentUser);
    let headers = buildHeaders(currentUser)
  try {
    const payload = yield call(apiRequest, `/items/${action.payload.id}`, {
      method: 'DELETE',
      headers: headers,
    });
    yield put({type: actions.removeSuccess, payload: payload});
  } catch (e) {
    yield put({type: actions.removeFail, payload: e});
  }
}

/** Unstore Saga
 *  @description: handles the cache cleaning when an item is unstored
 */
export function* unstoreItem(action) {
  const image = yield select(state => state.items.show.image);
  caches.open('images-cache')
  .then(imagesCache => {
    console.log(imagesCache)
    return imagesCache.delete(image)
  })
  .then(response => {
    console.log(response)
  })
  .catch(err => {
    console.log('Could not clear cahe', err);
    
  })
}

/**
 * Saga Watchers
 * The exported list of sagas registered. When one of the action types is dispatched 
 * the related worker saga is invoked.
 * Each saga is executed in a different thread
 */
function* itemsSaga() {
  yield takeEvery(actions.list, list);
  yield takeEvery(actions.show, show);
  yield takeEvery(actions.create, create);
  yield takeEvery(actions.update, update);
  yield takeEvery(actions.remove, remove);
  yield takeEvery(actions.unstoreItem, unstoreItem);
}

export default itemsSaga;
