/**
 * Actions and ActionTypes definitions for the Xapi resource managment.
 * ActionTypes are the constants used to specify a specific action.
 * Actions are generated with redux-actions' createAction method.
 * Each action involving a fetch request also defines a SUCCESS and FAIL action called after the fetch result
 */

import { createAction } from 'redux-actions';

/** Load home action-types */
const HOME = 'xapi/HOME';
const HOME_SUCCESS = 'xapi/HOME_SUCCESS';
const HOME_FAIL = 'xapi/HOME_FAIL';

/**
 * Loads data for the home page
 */
const loadHomeSuccess = createAction(HOME_SUCCESS);
const loadHomeFail = createAction(HOME_FAIL);
const loadHome = createAction(HOME);


export default {
  loadHome: loadHome,
  loadHomeSuccess: loadHomeSuccess,
  loadHomeFail: loadHomeFail
}
