/** 
 * The Root container
*/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { Route, Switch, Redirect } from 'react-router-dom'
import { StickyContainer, Sticky } from 'react-sticky';
import Navbar from '../components/Navbar'
// import Footer from '../components/Footer'
import OfflineBar from '../components/OfflineBar'
import Loadable from 'react-loadable';
import FourOFour from './FourOFour';
import PrivateRoute from '../components/PrivateRoute'

/**
 * Loadable import for Home container
 */
const Home = Loadable({
  loader: () => import(/* webpackChunkName: "Home" */ './Home'),
  loading: () => null,
  modules: ['Home']
});

/**
 * Loadable import for Account container
 */
const Account = Loadable({
  loader: () => import(/* webpackChunkName: "Account" */ './Account'),
  loading: () => null,
  modules: ['Account']
});

// DON'T DELETE THE FOLLOWING COMMENT: NEEDED FOR UPDATING THIS FILE WITH THE SCAFFOLD SCRIPT
// new scaffolded loadable imports
// Loadable import for CmsPage container
const CmsPage = Loadable({
  loader: () => import(/* webpackChunkName: "CmsPage" */ './CmsPage'),
  loading: () => null,
  modules: ['CmsPage']
});
// Loadable import for Training container
const Training = Loadable({
  loader: () => import(/* webpackChunkName: "Training" */ './Training'),
  loading: () => null,
  modules: ['Training']
});
// Loadable import for Trainings container
const Trainings = Loadable({
  loader: () => import(/* webpackChunkName: "Trainings" */ './Trainings'),
  loading: () => null,
  modules: ['Trainings']
});
// Loadable import for MissingTraining container
const ErrorRoute = Loadable({
  loader: () => import(/* webpackChunkName: "ErrorRoute" */ './ErrorRoute'),
  loading: () => null,
  modules: ['ErrorRoute']
});

// Loadable import for Shop container
const Shop = Loadable({
  loader: () => import(/* webpackChunkName: "Shop" */ './Shop'),
  loading: () => null,
  modules: ['Shop']
});

/**
 * The Root container used as entry point for the react-router. 
 * All routes are lazy-loaded with Loadable.
 */
class Root extends Component {

  render() {
    let backTarget = null;
    // let showFooter = false
    if (this.props.location.pathname.includes('/trainings/')) {
      backTarget=this.props.location.pathname.split('/trainings/')[0]+'/trainings';
    }
    if (this.props.location.pathname === '/trainings') {
      backTarget='/'
    }
    // if (this.props.location.pathname === '/' || this.props.location.pathname.includes('/account/')) {
    //   showFooter = true
    // }
    const search = this.props.location.search
    return (
      <StickyContainer id="Root">
        <OfflineBar />
        <Sticky topOffset={1} disableCompensation>
          { ({style, isSticky}) => <Navbar style={ {...style} } isSticky={isSticky} backTarget={backTarget} />}
        </Sticky>
        <div id="content">
          <Switch>
            <Redirect exact from="/" to={`/index.html${search}`} />
            {/* <PrivateRoute path={"/"} exact component={Home} /> */}
            <Route path={"/index.html"} component={Home} />
            <Route path={"/account/login"} component={Account} />
            <Route path={"/account/register"} component={Account} />
            <Route path={"/account/recover"} component={Account} />
            <Route path={"/account/reset"} component={Account} />
            {/* // DON'T DELETE THE FOLLOWING COMMENT: NEEDED FOR UPDATING THIS FILE WITH THE SCAFFOLD SCRIPT */}
            {/* new routes */}
						<Route exact path={'/cmspages/:slug?'} component={ CmsPage } />
						<Route exact path="/home" component={ Home } />
						<PrivateRoute exact path={'/shop'} component={ Shop } />
						<PrivateRoute exact path="/trainings" component={ Trainings } />
						<PrivateRoute exact path={'/trainings/:id?/:slide?'} component={ Training } />
						<PrivateRoute exact path={'/account/profile'} component={ Account } />
            <PrivateRoute path="/error/:type?/:id?" component={ ErrorRoute } />
            <Route component={FourOFour} />
          </Switch>
        </div>
        {/* { showFooter &&
          <Footer />
        } */}
      </StickyContainer>
    );
  }
}

/**
 * Selects props from the store and passes them to the component
 * @param {Object} state the store
 */
const mapStateToProps = state => ({});
/**
 * Binds dispatch method to the actions passed as props to the component
 */
const mapDispatchToProps = dispatch =>
  bindActionCreators({}, dispatch);

/** export the redux connected component and add a frontload call */
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Root)
);
