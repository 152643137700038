/**
 * Actions and ActionTypes definitions for the Xapi resource managment.
 * ActionTypes are the constants used to specify a specific action.
 * Actions are generated with redux-actions' createAction method.
 * Each action involving a fetch request also defines a SUCCESS and FAIL action called after the fetch result
 */

import { createAction } from 'redux-actions';

/** Load send mail action-types */
const SEND_MAIL = 'shop/SEND_MAIL';
const SEND_MAIL_SUCCESS = 'shop/SEND_MAIL_SUCCESS';
const SEND_MAIL_FAIL = 'shop/SEND_MAIL_FAIL';

const RESET = 'shop/RESET'
/**
 * 
 */
const sendMailSuccess = createAction(SEND_MAIL_SUCCESS);
const sendMailFail = createAction(SEND_MAIL_FAIL);
const sendMail = createAction(SEND_MAIL);

const reset = createAction(RESET);


export default {
  sendMail: sendMail,
  sendMailSuccess: sendMailSuccess,
  sendMailFail: sendMailFail,
  reset: reset,
}
