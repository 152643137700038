/**
 *  Reducer function for the xapis resource management
 * 
 * It exports a defaultState used as initial state for this reducer.
 * Each action is handled with the handleActions method from redux-actions and is bound to the actions defined in actions/xapis
 * 
 */
import { handleActions } from 'redux-actions';
import actions from './actions';

/** The article reducer initial state */
export const defaultState = {
  home: null,
  loadingHome: false,
  loadedHome: false,
  homeError: null,
};

/** List of actions handled by reducer */
export default handleActions(
  {
    [actions.loadHome]: (state) => {
      return {
        ...state,
        loadingHome: true,
        loadedHome: false,
        homeError: null,
      };
    },
    [actions.loadHomeSuccess]: (state, action) => {
      return {
        ...state,
        loadingHome: false,
        loadedHome: true,
        home: action.payload
      };
    },
    [actions.loadHomeFail]: (state, action) => {
      return {
        ...state,
        loadingHome: false,
        loadedHome: false,
        homeError: action.payload.mess
      };
    },
      },
  defaultState
);
