/**
 * Actions and ActionTypes definitions for managing push subscriptions. The store action saves the client subscription data in the store, the send method updates the same values in the api.
 * ActionTypes are the constants used to specify a specific action.
 * Actions are generated with redux-actions' createAction method.
 * Each action involving a fetch request also defines a SUCCESS and FAIL action called after the fetch result
 */
import { createAction } from 'redux-actions';

/** Store push subscription action-types */
const STORE_PUSH_SUBSCRIPTION = 'serviceWorker/STORE_PUSH_SUBSCRIPTION';

/** Send push subscription action-types */
const SEND_PUSH_SUBSCRIPTION = 'serviceWorker/SEND_PUSH_SUBSCRIPTION';
const SEND_PUSH_SUBSCRIPTION_SUCCESS = 'serviceWorker/SEND_PUSH_SUBSCRIPTION_SUCCESS';
const SEND_PUSH_SUBSCRIPTION_FAIL = 'serviceWorker/SEND_PUSH_SUBSCRIPTION_FAIL';

/**
 * Store push subscription action
 */
const storePushSubscription = createAction(STORE_PUSH_SUBSCRIPTION);

/**
 * Send push subscription actions
 */
const sendPushSubscription = createAction(SEND_PUSH_SUBSCRIPTION);
const sendPushSubscriptionSuccess = createAction(SEND_PUSH_SUBSCRIPTION_SUCCESS);
const sendPushSubscriptionFail = createAction(SEND_PUSH_SUBSCRIPTION_FAIL);

/** exports all actions as default */
export default {
  storePushSubscription: storePushSubscription,
  sendPushSubscription: sendPushSubscription,
  sendPushSubscriptionSuccess: sendPushSubscriptionSuccess,
  sendPushSubscriptionFail: sendPushSubscriptionFail,
};
