/**
 * Actions and ActionTypes definitions for the Training resource managment.
 * ActionTypes are the constants used to specify a specific action.
 * Actions are generated with redux-actions' createAction method.
 * Each action involving a fetch request also defines a SUCCESS and FAIL action called after the fetch result
 */
import { createAction } from 'redux-actions';

/** LIST trainings action-types */
const LIST = 'trainings/LIST';
const LIST_SUCCESS = 'trainings/LIST_SUCCESS';
const LIST_FAIL = 'trainings/LIST_FAIL';

/** SHOW training action-types */
const SHOW = 'trainings/SHOW';
const SHOW_SUCCESS = 'trainings/SHOW_SUCCESS';
const SHOW_FAIL = 'trainings/SHOW_FAIL';

/** CREATE training action-types */
const CREATE = 'trainings/CREATE';
const CREATE_SUCCESS = 'trainings/CREATE_SUCCESS';
const CREATE_FAIL = 'trainings/CREATE_FAIL';



/**
 * list trainings
 */
const listSuccess = createAction(LIST_SUCCESS);
const listFail = createAction(LIST_FAIL);
const list = createAction(LIST);

/**
 * show training
 */
const showSuccess = createAction(SHOW_SUCCESS);
const showFail = createAction(SHOW_FAIL);
const show = createAction(SHOW);

/**
 * create training
 */
const createSuccess = createAction(CREATE_SUCCESS);
const createFail = createAction(CREATE_FAIL);
const create = createAction(CREATE);




/** exports all actions as default */
export default {
  list: list,
  listSuccess: listSuccess,
  listFail: listFail,
  show: show,
  showSuccess: showSuccess,
  showFail: showFail,
  create: create,
  createSuccess: createSuccess,
  createFail: createFail,

}
