/**
 * The navbar container
 */
import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem } from 'reactstrap';
import { connect } from "react-redux";
// import PropTypes from "prop-types";
import { NavLink, Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { push } from 'connected-react-router'
import {setLanguage} from "redux-i18n";
import FlashAlert from '../FlashAlert';
import avatar from '../../assets/images/avatar-placeholder.png';
import avatarRetina from '../../assets/images/avatar-placeholder@2x.png';
import accountActions from '../../redux/account';
import { FaChevronLeft, FaSignOutAlt, FaUserPlus, FaUser, FaLanguage, FaInfoCircle, FaShoppingCart, FaList } from 'react-icons/fa';

import logo from '../../assets/images/logo.png';

/**
 * Selects props from the store and passes them to the component
 * @param {Object} state the store
 */
const mapStateToProps = state => ({
  alertMessage: state.notifications.message,
  alertColor: state.notifications.color,
  alertTimeout: state.notifications.timeout,
  lang: state.i18nState.lang,
  currentUser: state.account.currentUser,
  signedin: state.account.signedin

 })
 /**
 * Binds dispatch method to the actions passed as props to the component
 */
 const mapDispatchToProps = dispatch => bindActionCreators({
    setLanguage: setLanguage,
    navigateTo: push,
    logout: accountActions.logout,
   },
   dispatch
 )

/**
 * List of languages supported
 */
const languages = {
  'it': 'Italiano',
  'en': 'English',
  'de': 'Deutsch'
}
/**
 * The navigation bar container. Renders the top bar with links to navigte in the site
 */
export class Navigationbar extends Component {

  /**
   * @constructor binds the toggle method and sets an isOpen boolean in the state
   * @param {} props 
   */
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.closeMobile = this.closeMobile.bind(this);
    this.state = {
      isOpen: false
    };
  }

  /** toggles the open state of the navbar */
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  closeMobile() {
    this.setState({
      isOpen: false
    });
  }

  /** handles all navigation links */
  handleNavigationAction = (path, event) => {
    event.preventDefault();
    this.closeMobile(this);
    this.props.navigateTo(path);
  }

  /** handles the change language event */
  handleI18nAction = (lang, event) => {
    event.preventDefault();
    this.props.setLanguage(lang);
  }

  /** handles the click on logout */
  handleLogoutAction = (event) => {
    event.preventDefault();
    this.closeMobile(this)
    const {currentUser} = this.props
    this.props.logout(currentUser.tokens);
  }

  /** renders the user avatar in the navbar */
  renderAvatar() {
    const {signedin, currentUser} = this.props;
    var avatarSource = avatar;
    var avatarRetinaSource = avatarRetina;
    var alt = 'profile img placeholder';
    if (signedin && currentUser && currentUser.avatar){
      alt = currentUser.name;
      if (currentUser.avatar !== '/images/avatar-placeholder.png') {
        avatarSource = currentUser.avatar;
        avatarRetinaSource = currentUser.avatar;
      }
    }
    return (<img src={avatarSource}  width="38" height="38" data-src-retina={avatarRetinaSource} alt={alt} className="rounded-circle"/>);
  }


  render() {
    const { signedin, backTarget } = this.props
    const { alertMessage, alertColor, alertTimeout} = this.props
    return (
      <div style={this.props.style} className={classNames('navigationbar ', {'navigationbar--sticky': this.props.isSticky})}>
        <Navbar color="dark" expand="sm" dark>
          <div>
            { backTarget &&
              <Link
                to={backTarget}
                className='navigationbar__back--mobile btn btn-link text-white'
                onClick={this.closeMobile.bind(this)}>
                <FaChevronLeft />
                <span className='sr-only'>{this.context.t("Back")}</span>
              </Link>
            }
            { !backTarget &&
              <span className='btn btn-link invisible'>
                <FaChevronLeft />
              </span>
            }
            <NavbarBrand href="#" onClick={this.handleNavigationAction.bind(this, '/home')}>
              <img width="44" height="44" src={logo} data-src-retina={logo} alt="logo"/>
              <span className="ml-2">{this.context.t("Barrierefreifahrt")}</span>
            </NavbarBrand>
          </div>
          <NavbarToggler aria-label="navigation_toggle" onClick={this.toggle} />
          
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto" navbar>
              { signedin &&
                <NavItem>
                  <NavLink to="/account/profile" className="nav-link" onClick={this.closeMobile.bind(this)}><FaUser className="mx-3"/>{this.context.t("Profile")}</NavLink>
                </NavItem>
              }
              { signedin &&
                <NavItem>
                  <NavLink to="/trainings" className="nav-link" onClick={this.closeMobile.bind(this)}><FaList className="mx-3"/>{this.context.t("Trainings")}</NavLink>
                </NavItem>
              }
              <NavItem>
                <NavLink to="/shop" className="nav-link" onClick={this.closeMobile.bind(this)}><FaShoppingCart className="mx-3"/>{this.context.t("Shop")}</NavLink>
              </NavItem>
              { signedin && 
                <NavItem>
                  <NavLink to="/cmspages/about" className="nav-link" onClick={this.closeMobile.bind(this)}><FaInfoCircle className="mx-3"/>{this.context.t("About")}</NavLink>
                </NavItem>
              }
              { !signedin &&
                <NavItem>
                  <NavLink to="/account/login" className="nav-link" onClick={this.closeMobile.bind(this)}><FaUser className="mx-3"/>{this.context.t("Login")}</NavLink>
                </NavItem>
              }
              { !signedin &&
                <NavItem>
                  <NavLink to="/account/register" className="nav-link" onClick={this.closeMobile.bind(this)}><FaUserPlus className="mx-3"/>{this.context.t("Register")}</NavLink>
                </NavItem>
              } 
              <NavItem>
                <NavLink to="/cmspages/imprint" className="nav-link" onClick={this.closeMobile.bind(this)}>{this.context.t("Imprint")}</NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/cmspages/data_privacy" className="nav-link" onClick={this.closeMobile.bind(this)}>{this.context.t("Dataprivacy")}</NavLink>
              </NavItem>
              { signedin && 
                <NavItem>
                  <button onClick={this.handleLogoutAction.bind(this)} className="btn btn-link nav-link"><FaSignOutAlt className="mx-3"/>{this.context.t("Logout")}</button>
                </NavItem>
              }

              { false &&
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  <FaLanguage className="mx-3"/>
                  {languages[this.props.lang]}
                </DropdownToggle>
                <DropdownMenu right>
                  { Object.keys(languages).map(language => {
                    if (language !== this.props.lang) {
                      return <DropdownItem key={language} onClick={this.handleI18nAction.bind(this, language)}>{languages[language]}</DropdownItem>
                    } else return '';
                  })}
                </DropdownMenu>
              </UncontrolledDropdown>
              }
            </Nav>
          </Collapse>
        </Navbar>
        { alertMessage && 
          <FlashAlert color={alertColor || 'success'} timeout={alertTimeout}>{alertMessage}</FlashAlert>
        }
      </div>
    );
  }
}
/**  define proptype for the 'translation' function  */
Navigationbar.contextTypes = {
  t: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(Navigationbar);
