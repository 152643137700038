/**
 * Sagas for the training resource managment
 * 
 * Each saga watcher intercepts a trigger action, does the asyncrhonous work in the respective worker saga and dispatches a success or a failure action.
 */
import { call, put, takeEvery, select } from 'redux-saga/effects'
import apiRequest from '../../utils/apiRequest';
import buildHeaders from '../../utils/buildHeaders';
import actions from './actions';
import { push } from 'connected-react-router';
import {isServer} from "../store";

/** Worker Sagas */

/** List Saga
 *  @description: connects to the `index` endpoint to fetch a list of trainings
 */
export function* list(action) {
  /** if we are offline we use persisted data */
  if ( !isServer && navigator && !navigator.onLine) { 
    let storedList = []
    const storedTrainings = yield select(state => state.trainings.stored);
    Object.keys(storedTrainings).forEach(storedTrainingIndex => {
      storedList.push(Object.assign({}, storedTrainings[storedTrainingIndex]))
    })
    yield put({ type: actions.listSuccess, payload: storedList });
  } else {
    /**  else we are online -> we fetch */
    let headers = buildHeaders()
    try {
      const payload = yield call(apiRequest, `/trainings.json`, {headers: headers});
      yield put({type: actions.listSuccess, payload: payload});
    } catch (e) {
      yield put({type: actions.listFail, payload: e});
    }
  }
}
/** Show Saga
 *  @description: connects to the `show` endpoint to fetch an training by id
 *  @param {number} action.payload the training id
 */
export function* show(action) {
  const resourceId = action.payload;
  const storedTrainings = yield select(state => state.trainings.stored);
  /** if we are offline we use persisted data */
  if (!isServer && (navigator && !navigator.onLine) && storedTrainings[resourceId]) {
    yield put({
      type: actions.showSuccess,
      payload: storedTrainings[resourceId]
    });
  } else {
    /**  else we are online -> we fetch */
    let headers = buildHeaders()
    try {
      const payload = yield call(apiRequest, `/trainings/${resourceId}.json`, {headers: headers});
      yield put({type: actions.showSuccess, payload: payload});
    } catch (e) {
      yield put({type: actions.showFail, payload: e});
    }
  }
}
/** Create Saga
 *  
 */
export function* create(action) {
  let headers = buildHeaders();
  let values = action.payload;
  let body = JSON.stringify(values)
  try {
    const payload = yield call(apiRequest, `/user_trainings`, { body, method: 'POST', headers });
    yield put({type: actions.createSuccess, payload: payload});
    yield put(push('/trainings/' + values.training_id + '/success'));
  } catch (e) {
    yield put({type: actions.createFail, payload: e});
  }
}


/**
 * Saga Watchers
 * The exported list of sagas registered. When one of the action types is dispatched 
 * the related worker saga is invoked.
 * Each saga is executed in a different thread
 */
function* trainingsSaga() {
  yield takeEvery(actions.list, list);
  yield takeEvery(actions.show, show);
  yield takeEvery(actions.create, create);
}

export default trainingsSaga;
