/**
 * Actions and ActionTypes definitions for the user account managment.
 * ActionTypes are the constants used to specify a specific action.
 * Actions are generated with redux-actions' createAction method.
 * Each action involving a fetch request also defines a SUCCESS and FAIL action called after the fetch result
 */
import { createAction } from 'redux-actions';

/** Login action-types */
const LOGIN_ACTION = 'account/LOGIN_ACTION';
const LOGIN_ACTION_SUCCESS = 'account/LOGIN_ACTION_SUCCESS';
const LOGIN_ACTION_FAIL = 'account/LOGIN_ACTION_FAIL';

/** Logout action-types */
const LOGOUT_ACTION = 'account/LOGOUT_ACTION';
const LOGOUT_ACTION_SUCCESS = 'account/LOGOUT_ACTION_SUCCESS';
const LOGOUT_ACTION_FAIL = 'account/LOGOUT_ACTION_FAIL';

/** Register action-types */
const REGISTER_ACTION = 'account/REGISTER_ACTION';
const REGISTER_ACTION_SUCCESS = 'account/REGISTER_ACTION_SUCCESS';
const REGISTER_ACTION_FAIL = 'account/REGISTER_ACTION_FAIL';

/** Social Login action-types */
const SOCIAL_LOGIN_ACTION = 'account/SOCIAL_LOGIN_ACTION';
const SOCIAL_LOGIN_ACTION_SUCCESS = 'account/SOCIAL_LOGIN_ACTION_SUCCESS';
const SOCIAL_LOGIN_ACTION_FAIL = 'account/SOCIAL_LOGIN_ACTION_FAIL';

/** Password Recovery action-types */
const PWD_RECOVERY_ACTION = 'account/PWD_RECOVERY_ACTION';
const PWD_RECOVERY_ACTION_SUCCESS = 'account/PWD_RECOVERY_ACTION_SUCCESS';
const PWD_RECOVERY_ACTION_FAIL = 'account/PWD_RECOVERY_ACTION_FAIL';

/** Password Reset action-types */
const PWD_RESET_ACTION = 'account/PWD_RESET_ACTION';
const PWD_RESET_ACTION_SUCCESS = 'account/PWD_RESET_ACTION_SUCCESS';
const PWD_RESET_ACTION_FAIL = 'account/PWD_RESET_ACTION_FAIL';

/** CreateUserTraining action-types */
const CREATE_USER_TRAINING_ACTION = 'account/CREATE_USER_TRAINING_ACTION';
const CREATE_USER_TRAINING_ACTION_SUCCESS = 'account/CREATE_USER_TRAINING_ACTION_SUCCESS';
const CREATE_USER_TRAINING_ACTION_FAIL = 'account/CREATE_USER_TRAINING_ACTION_FAIL';

const RESET_SUCCESS_MESSAGE = 'account/RESET_SUCCESS_MESSAGE';

/** Remove action-types */
const REMOVE_ACTION = 'account/REMOVE_ACTION';
const REMOVE_ACTION_SUCCESS = 'account/REMOVE_ACTION_SUCCESS';
const REMOVE_ACTION_FAIL = 'account/REMOVE_ACTION_FAIL';

/**
 * login action
 */
const loginSuccess = createAction(LOGIN_ACTION_SUCCESS);
const loginFail = createAction(LOGIN_ACTION_FAIL);
const login = createAction(LOGIN_ACTION);

/**
 * logout action
 */
const logoutSuccess = createAction(LOGOUT_ACTION_SUCCESS);
const logoutFail = createAction(LOGOUT_ACTION_FAIL);
const logout = createAction(LOGOUT_ACTION);

/**
 * social login action
 */
const socialLoginSuccess = createAction(SOCIAL_LOGIN_ACTION_SUCCESS);
const socialLoginFail = createAction(SOCIAL_LOGIN_ACTION_FAIL);
const socialLogin = createAction(SOCIAL_LOGIN_ACTION);

/**
 * register action
 */
const registerSuccess = createAction(REGISTER_ACTION_SUCCESS);
const registerFail = createAction(REGISTER_ACTION_FAIL);
const register = createAction(REGISTER_ACTION);

/**
 * pwdRecovery action
 */
const pwdRecoverySuccess = createAction(PWD_RECOVERY_ACTION_SUCCESS);
const pwdRecoveryFail = createAction(PWD_RECOVERY_ACTION_FAIL);
const pwdRecovery = createAction(PWD_RECOVERY_ACTION);

/**
 * pwdReset action
 */
const pwdResetSuccess = createAction(PWD_RESET_ACTION_SUCCESS);
const pwdResetFail = createAction(PWD_RESET_ACTION_FAIL);
const pwdReset = createAction(PWD_RESET_ACTION);

/**
 * createUserTraining action
 */
const createUserTrainingSuccess = createAction(CREATE_USER_TRAINING_ACTION_SUCCESS);
const createUserTrainingFail = createAction(CREATE_USER_TRAINING_ACTION_FAIL);
const createUserTraining = createAction(CREATE_USER_TRAINING_ACTION);

const resetSuccessMessage = createAction(RESET_SUCCESS_MESSAGE);

/**
 * remove action
 */
const removeSuccess = createAction(REMOVE_ACTION_SUCCESS);
const removeFail = createAction(REMOVE_ACTION_FAIL);
const remove = createAction(REMOVE_ACTION);

/** exports all actions as default */
export default {
  login: login,
  loginSuccess: loginSuccess,
  loginFail: loginFail,
  logout: logout,
  logoutSuccess: logoutSuccess,
  logoutFail: logoutFail,
  register: register,
  registerSuccess: registerSuccess,
  registerFail: registerFail,
  socialLogin: socialLogin,
  socialLoginSuccess: socialLoginSuccess,
  socialLoginFail: socialLoginFail,
  pwdRecovery: pwdRecovery,
  pwdRecoverySuccess: pwdRecoverySuccess,
  pwdRecoveryFail: pwdRecoveryFail,
  pwdReset: pwdReset,
  pwdResetSuccess: pwdResetSuccess,
  pwdResetFail: pwdResetFail,
  createUserTraining: createUserTraining,
  createUserTrainingSuccess: createUserTrainingSuccess,
  createUserTrainingFail: createUserTrainingFail,
  resetSuccessMessage: resetSuccessMessage,
  remove: remove,
  removeSuccess: removeSuccess,
  removeFail: removeFail
};
