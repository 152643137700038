/**
 *  Reducer function for the cmspages resource management
 * 
 * It exports a defaultState used as initial state for this reducer.
 * Each action is handled with the handleActions method from redux-actions and is bound to the actions defined in actions/cmspages
 * 
 */
import { handleActions } from 'redux-actions';
import actions from './actions';


/** The cmspages reducer initial state */
export const defaultState = {
  // stored: {},
  list: [],
  loadingList: false,
  loadedList: false,
  listError: null,
  show: null,
  loadingShow: false,
  loadedShow: false,
  showError: null,
  creating: false,
  created: false,
  createError: null,
  updating: false,
  updated: false,
  updateError: null,
  removing: false,
  removed: false,
  removeError: null
};

/** List of actions handled by reducer */
export default handleActions(
  {
    /** The cmspages list action trigger
     * @param {Object} state the current state of this reducer
     */
    [actions.list]: (state) => {
      return {
        ...state,
        loadingList: true,
        loadedList: false,
        listError: null,
      };
    },
    /** The cmspages list action success
     * @param {Object} state the current state of this reducer
     * @param {Object} action.payload the list of cmspages
     */
    [actions.listSuccess]: (state, action) => {
      return {
        ...state,
        loadingList: false,
        loadedList: true,
        list: action.payload
      };
    },
    /** The cmspages list action fail
     * @param {Object} state the current state of this reducer
     * @param {Object} action.payload the error message
     */
    [actions.listFail]: (state, action) => {
      return {
        ...state,
        loadingList: false,
        loadedList: false,
        listError: action.payload.mess
      };
    },
    /** The cmspage show action trigger
     * @param {Object} state the current state of this reducer
     */
    [actions.show]: (state) => {
      return {
        ...state,
        loadingShow: true,
        loadedShow: false,
        showError: null,
      };
    },
    /** The cmspage show action success
     * @param {Object} state the current state of this reducer
     * @param {Object} action.payload the requested cmspage
     */
    [actions.showSuccess]: (state, action) => {
      return {
        ...state,
        loadingShow: false,
        loadedShow: true,
        show: action.payload
      };
    },
    /** The cmspage show action fail
     * @param {Object} state the current state of this reducer
     * @param {Object} action.payload the error message
     */
    [actions.showFail]: (state, action) => {
      return {
        ...state,
        loadingShow: false,
        loadedShow: false,
        showError: action.payload.mess
      };
    },
    /** The cmspage create action trigger
     * @param {Object} state the current state of this reducer
     */
    [actions.create]: (state) => {
      return {
        ...state,
        creating: true,
        created: false,
        createError: null,
      };
    },
    /** The cmspage create action success
     * @param {Object} state the current state of this reducer
     * @param {Object} action.payload the created cmspage
     */
    [actions.createSuccess]: (state, action) => {
      var newList = state.list.push(action.payload)
      return {
        ...state,
        list: newList,
        creating: false,
        created: true,
        show: action.payload
      };
    },
    /** The cmspage create action fail
     * @param {Object} state the current state of this reducer
     * @param {Object} action.payload the error message
     */
    [actions.createFail]: (state, action) => {
      return {
        ...state,
        creating: false,
        created: false,
        createError: action.payload.mess
      };
    },
    /** The cmspage update action trigger
     * @param {Object} state the current state of this reducer
     */
    [actions.update]: (state) => {
      return {
        ...state,
        updating: true,
        updated: false,
        updateError: null,
      };
    },
    /** The cmspage update action success
     * @param {Object} state the current state of this reducer
     * @param {Object} action.payload the updated of cmspages
     */
    [actions.updateSuccess]: (state, action) => {
      return {
        ...state,
        updating: false,
        updated: true,
        show: action.payload
      };
    },
    /** The cmspage update action fail
     * @param {Object} state the current state of this reducer
     * @param {Object} action.payload the error message
     */
    [actions.updateFail]: (state, action) => {
      return {
        ...state,
        updating: false,
        updated: false,
        updateError: action.payload.mess
      };
    },
    /** The cmspage remove action trigger
     * @param {Object} state the current state of this reducer
     */
    [actions.remove]: (state) => {
      return {
        ...state,
        removing: true,
        removed: false,
        removeError: null,
      };
    },
    /** The cmspage remove action success
     * @param {Object} state the current state of this reducer
     */
    [actions.removeSuccess]: (state) => {
      return {
        ...state,
        removing: false,
        removed: true,
      };
    },
    /** The cmspage remove action fail
     * @param {Object} state the current state of this reducer
     * @param {Object} action.payload the error message
     */
    [actions.removeFail]: (state, action) => {
      return {
        ...state,
        removing: false,
        removed: false,
        removeError: action.payload.mess
      };
    },
    /** The store cmspage action. Saves the currently shown cmspage in the persisted storage
     * @param {Object} state the current state of this reducer
     */
    [actions.storeCmsPage]: (state) => {
      let newStoredCmsPages = Object.assign({}, state.stored)
      newStoredCmsPages[state.show.id] = state.show;
      let newShow = Object.assign({}, state.show);
      newShow.stored = true;
      return {
        ...state,
        stored: newStoredCmsPages,
        show: newShow
      };
    },
    /** The unstore cmspage action. Removes the currently shown cmspage from the persisted storage
     * @param {Object} state the current state of this reducer
     * @param {Object} action.payload the id of the cmspage to unstore
     */
    [actions.unstoreCmsPage]: (state, action) => {
      let newStoredCmsPages = Object.assign({}, state.stored)
      delete newStoredCmsPages[action.payload];
      let newShow = Object.assign({}, state.show);
      newShow.stored = false;
      return {
        ...state,
        stored: newStoredCmsPages,
        show: newShow
      };
    }
  },
  defaultState
);
