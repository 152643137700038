/**
 *  Sagas for the cmspage resource managment using Swagger client
 * 
 * Each saga watcher intercepts a trigger action, does the asyncrhonous work in the respective worker saga and dispatches a success or a failure action.
 * Fetch calls are made via the swagger tags interface requests
 * 
 * TODO: update and create are not working through swagger client cause we dont know how to upload a picture along an id
 */

import { call, put, takeEvery, select } from 'redux-saga/effects'
import buildHeaders from '../../utils/buildHeaders';
import actions from './actions';
import apiClient, {applyHeaders} from '../../utils/apiSwaggerRequest';

/** Worker Sagas */

/** List Saga
 *  @description: connects to the getCmsPages operation
 */
export function* sendMail(action) {

  let values = {...action.payload};

  const currentUser = yield select(state => state.account.currentUser);
  let headers = buildHeaders(currentUser)
  try {
    const SwaggerClient = yield call(apiClient)
    const payload = yield call(SwaggerClient.apis.Shop.sendShopMail, {shop_data: values}, {requestInterceptor: applyHeaders(headers)})
    yield put({type: actions.sendMailSuccess, payload: payload.obj});
  } catch (e) {
    yield put({type: actions.sendMailFail, payload: e});
  }
}


/**
 * Saga Watchers
 * The exported shopSaga of sagas registered. When one of the action types is dispatched 
 * the related worker saga is invoked.
 * Each saga is executed in a different thread
 */
function* shopSaga() {
  yield takeEvery(actions.sendMail, sendMail);
}
export default shopSaga;
