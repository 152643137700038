/**
 *  Reducer function for the shop resource management
 * 
 * It exports a defaultState used as initial state for this reducer.
 * Each action is handled with the handleActions method from redux-actions and is bound to the actions defined in actions/xapis
 * 
 */
import { handleActions } from 'redux-actions';
import actions from './actions';

export const defaultState = {
  sent: false,
  sending: false,
  error: null,
};

/** List of actions handled by reducer */
export default handleActions(
  {
    [actions.sendMail]: (state) => {
      return {
        ...state,
        sending: true,
        sent: false,
        homeError: null,
      };
    },
    [actions.sendMailSuccess]: (state, action) => {
      return {
        ...state,
        sending: false,
        sent: true,
      };
    },
    [actions.sendMailFail]: (state, action) => {
      return {
        ...state,
        sending: false,
        sent: false,
        error: action.payload.mess
      };
    },
    [actions.reset]: (state, action) => {
      return {
        ...state,
        sending: false,
        sent: false,
        error: null
      };
    },
  },
  defaultState
);
